.iv__9060{
    padding: 2rem 6rem;
    background: wheat;
    display: flex;
    flex-direction: column ;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
}

.iv__9060-text_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin-bottom: 1rem;
}

.iv__9060-heading_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    /* margin-bottom: 12rem; */
}

.iv__9060-heading{
    font-family: "baskerville-display-pt", serif;
font-weight: 400;
font-style: italic;
    font-size: 100px;
    color:#3d472c;
    text-transform: capitalize;
}

.iv__9060-text{
    color:#3d472c;
    font-family: Poppins;
    font-size: 20px;
    text-align: center;
}

.iv__9060-text_span{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* align-items: center;
    justify-content: left; */
}

.iv__9060-stamp{
    height: 100px;
}

.iv__9060-first{
    font-weight: 800;
    font-size: 30px;
}

@media only screen and (max-width: 992px){
    .iv__9060-heading{
        font-size: 80px;
    }

    .iv__9060-text{
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px){
    .iv__9060-heading{
        font-size: 60px;
    }

    .iv__9060-text{
        font-size: 16px;
    }

    .iv__9060-stamp{
        height: 80px;
    }

    .iv__9060{
        padding: 2rem 4rem;
    }
}

@media only screen and (max-width: 600px){
    .iv__9060-heading{
        font-size: 45px;
    }

    .iv__9060-text{
        font-size: 15px;
    }

    .iv__9060-stamp{
        height: 60px;
    }

    .iv__9060{
        padding: 2rem 2rem;
    }

    .iv__9060-text_container{
        width: 80vw;
    }
}

@media only screen and (max-width: 425px){
    .iv__9060-text {
        font-size: 14px;
    }

    .iv__9060{
        padding: 2rem 1rem;
    }
}