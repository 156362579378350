.iv__features{
    /* padding: 2rem 6rem; */
    /* border-bottom: 2px solid black; */
    overflow: hidden;
}

.iv__features-container_scroll{
    display: flex;
    width: 400vw;
}

.iv__features-container-img{
    height: 550px;
}

.scroll-section-outer {
    overflow: hidden;
  }
  
  .scroll-section-inner {
    height: 100vh;
    width: 400vw;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
  .scroll-section {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px){
    .iv__features-container-img{
      height: 400px;
  }
  
  }

  @media only screen and (max-width: 600px){
    .iv__features-container-img{
      height: 290px;
  }

}

@media only screen and (max-width: 425px){
  .iv__features-container-img {
    height: 200px;
}
}
  
